.user {
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login {
    float: left;
    width: 340px;
    padding: 20px;
    background: #fcfeff;
}
