.aside {
    float: left;
    width: 200px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: scroll;
    background: #fcfeff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.logo {
    float: left;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
}

.logo img {
    width: 60px;
}

.menu {
    float: left;
    width: 100%;
}

.menu ul {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.menu ul li {
}

.menu ul li a {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    color: #333940;
}

.menu ul li:hover a {
    background-size: 100% 100%;
}

.header {
    float: left;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    display: none;
}

.header .logo {
    justify-content: flex-start;
}

.header .profile {
    justify-content: flex-end;
}

button:focus, button:hover, button:active {
    outline: none !important;
    box-shadow: none !important;
}
