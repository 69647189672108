@media only screen and (max-width: 600px) {
    .aside {
        display: none;
    }

    .header {
        position: fixed;
        z-index: 99;
        background: #fff;
        top: 0;
    }

    .right_container {
        width: 100%;
        top: 60px;
        padding: 0 10px;
    }

    .heading {
        width: 100%;
    }

    .logo {
        padding: 0 !important;
    }

    .logo img {
        width: 40px;
    }

    .table td, .table th {
        padding: 4px !important;
    }

    .list .item {
        width: 100%;
        height: 200px;
    }

    .header {
        display: flex;
    }

    .ReactModal__Content.ReactModal__Content--after-open.slide-pane.slide-pane_from_right {
        width: 100% !important;
    }

    .project_detail {
        width: 100%;
    }

    .project_images {
        width: 100%;
    }

    .pagination {
        position: fixed;
        bottom: 0;
        background: #ffffff;
    }

    .table {
        margin-bottom: 7rem !important;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 0;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 0;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 0;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: transparent;
    }
}

