ul {
    margin: 0 !important;
    padding: 0;
}

li {
    list-style: none;
}

a, a:hover {
    text-decoration: none !important;
}

.right_container {
    float: right;
    width: calc(100% - 200px);
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px 20px;
    box-sizing: border-box;
}

.heading-container {
    float: left;
    width: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    right: 0;
    background: #ffffff;
    padding: 0 20px;
}

.filters *{
    margin-right: 10px;
}
.heading {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading ul {
    display: flex;
    align-items: center;
}

.heading ul li {
    float: left;
    margin-right: 10px;
}

.heading ul li:last-child:after {
    content: '';
}

.heading ul li img {
    width: 20px;
}

.heading ul li a {
    background: #fcfeff;
    height: 35px;
    padding-left: 10px;
}

.dashboard {
    float: left;
    width: 100%;
}

.list .item {
    float: left;
    width: 25%;
    height: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.list .item a {
    float: left;
    width: 100%;
    text-align: center;
    color: #333940;
    text-decoration: none;
    font-size: 20px;
    font-weight: 300;
}

.list .item a h2 {
    font-size: 30px;
    font-weight: 300;
}


/***** Slide View ********/

.ReactModal__Content.ReactModal__Content--after-open.slide-pane.slide-pane_from_right {
    width: calc(100% - 80px) !important;
}

.static {
    pointer-events: none;
}

td ul {
    display: flex;
    flex-direction: row;
}

td ul li {
    margin-right: 10px;
}

td ul li:last-child {
    margin-right: 0;
}

.table-responsive {
    margin-top: 70px;
    max-height: calc(100vh - 130px);
}

.pagination {
    float: left;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.array{
    margin: 50px 0;
    border: solid 1px #000;
    padding: 5px;
    text-transform: capitalize;
}

.object{
    margin: 50px 0;
    border: solid 1px #000;
    padding: 5px;
    text-transform: capitalize;
}
.no-border{
    border: none!important;
}

.object .form-label, .array .form-label{
    display: block;
}

.editorClassName{
    max-height: 500px;
    text-transform: none;
    border: 1px solid #F1F1F1;
    padding-left: 5px;
    padding-right: 5px;
    min-height: 300px;
}
